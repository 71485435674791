<template>
    <Dropdown align="right" width="48">
        <template #trigger>
            <button v-if="$page.props.jetstream.managesProfilePhotos"
                class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition">
                <img class="h-8 w-8 rounded-full object-cover" :src="$page.props.auth.user.profile_photo_url"
                    :alt="$page.props.auth.user.name">
            </button>

            <span v-else class="inline-flex rounded-md">
                <button type="button"
                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none focus:bg-gray-50 active:bg-gray-50 transition ease-in-out duration-150">
                    {{ $page.props.auth.user.name }}

                    <svg class="ml-2 -mr-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                </button>
            </span>
        </template>

        <template #content>
            <!-- Account Management -->
            <div class="block px-4 py-2 text-xs text-gray-400">
                Il tuo Hotel (attendi verifica)
            </div>

            <DropdownLink :href="route('profile.show')">
                Profilo
            </DropdownLink>

            <DropdownLink v-if="$page.props.jetstream.hasApiFeatures" :href="route('api-tokens.index')">
                API Tokens
            </DropdownLink>

            <div class="border-t border-gray-200" />

            <!-- Authentication -->
            <form @submit.prevent="logout">
                <DropdownLink as="button">
                    Log Out
                </DropdownLink>
            </form>
        </template>
    </Dropdown>
</template>

<script>
import {  router } from '@inertiajs/vue3';
export default {
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        drawer() {
            this.isOpen = !this.isOpen;
        },
        logout() {
            router.post(route('logout'));
        }
    },
    watch: {
        isOpen: {
            immediate: true,
            handler(isOpen) {
                if (isOpen) document.body.style.setProperty("overflow", "hidden");
                else document.body.style.removeProperty("overflow");
            },
        },
    },
    mounted() {
        document.addEventListener("keydown", (e) => {
            if (e.keyCode == 27 && this.isOpen) this.isOpen = false;
        });
    },
};
</script>
