<!-- AppHead.vue -->

<script setup>
import { h } from 'vue'
const props = defineProps({ config: Object })
const metas = props.config?.meta?.map((m) => h('meta', m))
const links = props.config?.links?.map((m) => h('link', m))

</script>

<template>
    <Head>
        <component v-for="meta in metas" :is="meta" />
        <component v-for="link in links" :is="link" />
        <title v-if="props?.config?.title">{{ props.config.title }}</title>
        <link v-if="props.config?.favicon" rel="icon" type="image/svg+xml" :href="props.config.favicon" />
    </Head>
</template>
