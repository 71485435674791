<template>
    <div>
        <div class="flex">
            <font-awesome-icon @click="$refs.autoinput.clearInput()" icon="close" size="xs" class="closeIcon" />
            <simple-typeahead class="shadow-md lg:shadow-gray-200  shadow-gray-100"
            ref="autoinput" id="typeahead_id" placeholder="Cerca per Hotel..." :items="items"
                :minInputLength="2" :itemProjection="getResultValue" @selectItem="onSubmit" @onInput="search"
                :defaultItem="rememberedItem ? rememberedItem : null">
            </simple-typeahead>

        </div>
    </div>
</template>

<script>
import "@trevoreyre/autocomplete-js/dist/style.css";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'

export default {
    components: {
        //Autocomplete,
        SimpleTypeahead
    },
    computed: {
        /*         ...mapState({
                    mapLocation: (state) => state.map.location,
                }), */
    },
    props: {
        onlyHotels: Boolean, // Assuming onlyhotels is a boolean prop
        clearInput: Object
    },
    data() {
        return {
            items: [],
            rememberedItem: null,
            provider: null,
        };
    },
    emits: ["resultFound", "focused", "blurred"],
    watch: {
        input(val) {
            if (!val) {
                this.$emit('blurred')
            }
        },
        clearInput(location) {
            this.rememberedItem = null
            this.$refs.autoinput.clearInput()
            /*  console.log("location",location,this.$refs.autoinput.value)
             if (location.city) {
                 if (location.city != this.$refs.autoinput.value)
                     this.$refs.autoinput.clearInput()
                 console.log("this.rememberedItem", this.rememberedItem, location.city)
 
                 if (this.rememberedItem && this.rememberedItem.city != location.city) {
                     this.rememberedItem = null
 
                 }
             } */
        },
    },
    methods: {
        async search(input) {
            if (!input || !input.input)
                this.$emit('blurred')
            if (!input || !input.input || (input.input && input.input.length < 3)) return [];
            var hotels = await this.$api.like(input.input);
            hotels = hotels && hotels.length ? hotels.filter((h) => h.latitude && h.longitude) : [];
            var nominatim = this.onlyHotels ? [] : await this.provider.search({ query: { city: input.input } });
            console.log("nominatim", nominatim, hotels);
            this.items = [...nominatim, ...hotels];
        },
        getResultValue(result) {
            if (!result) return '';
            if (result.raw) return result.raw.display_name;
            //hotel
            else if (result.name)
                return `${result.name}, ${result.address}, ${result.city}`;
        },
        onSubmit(event) {
            console.log("event", event);
            const detail = {
                place_name: event.name ?? event.label,
                geometry: {
                    coordinates: [
                        event.longitude ?? event.raw.lon,
                        event.latitude ?? event.raw.lat,
                    ],
                },
            };
            event.detail = detail;
            console.log("faccio resultfound")
            this.$emit("resultFound", event);

        },
    },
    created() {
        var l = this.$page.props.location;
        console.log("location passata da props", l)
        // faccio attualmente solo gli hotel 
        if (l && l.type == 'hotel')
            this.rememberedItem = {
                detail: {
                    place_name: l.label,
                    geometry: {
                        coordinates: [l.lng, l.lat]
                    }
                },
                city: l.city,
                id: l.id,
                longitude: l.lng,
                latitude: l.lat,
                label: l.label,
                raw: {
                    display_name: l.label
                }

            };
    },
    async mounted() {

        const geo = await import("leaflet-geosearch");
        const config = {
            params: {
                limit: 5,
                "accept-language": "it", // render results in Dutch
                countrycodes: "it", // limit search results to the Netherlands
                addressdetails: 1,
                featureType: 'city'
            },
        }
        if (import.meta.env.VITE_NOMINATIM_HOST) {
            config.searchUrl = `http://${import.meta.env.VITE_NOMINATIM_HOST}:${import.meta.env.VITE_NOMINATIM_PORT}/search`;
            config.reverseUrl = `http://${import.meta.env.VITE_NOMINATIM_HOST}:${import.meta.env.VITE_NOMINATIM_PORT}/reverse`;
        }
        this.provider = new geo.OpenStreetMapProvider(config);

    },
};
</script>

<style lang="scss">
.autocomplete {
    @apply border-2 border-primary-300 border-opacity-80 rounded-lg text-lg p-2 pr-0;

    &:focus-within {
        outline: none;
    }

    .mapboxgl-ctrl-geocoder {
        @apply shadow-none min-w-0 sm:w-full;

        &--input {
            @apply p-0 pl-10  font-medium;

            &:focus {
                border: none;
                outline: none;
                box-shadow: none;
            }
        }
    }

}

/* .simple-typeahead-input:before {
content: '+';
display: inline-block;
width: 10px;
font-size:30px;
}
.simple-typeahead-input:after {
content: '\2212';
display: inline-block;
width: 10px;
font-size:30px;

}
 */
/* Default styles for .simple-typeahead-input */

/* Media query for mobile devices */

.simple-typeahead-input {
    @media (max-width: 768px) {
        width: calc(100% - 4px) !important;
        padding-right: 50px !important;
    }
}


.closeIcon {

    position: relative;
    left: 220px;
    z-index: 1000;
    top: 14px;

    @media (max-width: 768px) {

        position: relative;
        top: 15px;
        left: calc(100% - 20px);
        z-index: 1000;
    }
}
</style>
