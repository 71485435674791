<template>
    <div class="flex flex-col gap-1 lg:gap-4 app-search lg:flex-row lg:items-center">
        <div class="relative">
            <select v-model="location"
                class="block appearance-none  border border-gray-300 rounded py-2 leading-tight focus:outline-none focus:border-blue-500">
                <option v-for="(city, i) in cities" :key="city.name" :value="city" :disabled="city.name == 'Cerca per città'">
                    {{ city.name }}
                </option>
            </select>
        </div>
        <div>
            <p>oppure</p>
        </div>
        <NominatimGeocoder :clear-input="location" only-hotels class="lg:flex-1 lg:w-full" @resultFound="setLocationInfo" />
        <div class="lg:flex-1 lg:w-50 lg:min-w-[60%] lg:max-w-10">
            <div class="flex flex-col gap-1 lg:gap-4 app-search lg:flex-row lg:items-center">
                <label class="typo__label pt-3">Caratteristiche:</label>
                <div class="w-full">
                    <multiselect :limit="4" :limitText="() => 'Max 4 tag'" v-model="selectedTags"
                        tag-placeholder="Cerca per caratteristica" placeholder="Scegli caratteristica" :options="options" :multiple="true"
                        :taggable="true" @tag="addTag">
                    </multiselect>
                </div>
            </div>
        </div>
        <div class="lg:flex-1">
            <button aria-label="Search" class="w-full lg:w-20 app-button search-button p-2" @click="search">
                <SVGSearch />
                <p>Cerca</p>
            </button>
        </div>

    </div>
</template>



<script>

import NominatimGeocoder from "./NominatimGeocoder.vue";
//import { Multiselect } from "vue-multiselect/dist/vue-multiselect.ssr";
import { Multiselect } from "vue-multiselect";
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import { router, useRemember } from '@inertiajs/vue3'
import { availableCities,tagOptions } from "@/utils/hotelUtil"

//var selectedTags = useRemember([]);
var options = [...availableCities]
const location = { name: 'Cerca per città', lng: 0, lat: 0, lat: 0, label: "", city: "", type: "" };
options.unshift(
   location
);


export default {
    props: { ID: { type: String, required: false, default: "nav-search" } },
    data() {
        return {
            inputFocused: false,
            cities: options,
            selectedTags: [],
            visible: false,
            location,
            options: tagOptions,
            range: {
                start: "",
                end: "",
            },
            showTags: true,
        };
    },
    methods: {
        /*         ...mapActions("map", [
                    'changeLocation', "changeCenter", "changezoom", "changeTags"
                ]), */
        /*         cityHasChanged() {
                    this.location = this.cities.find(city => city.name == this.selectedCity)
                }, */
        addTag(tag) {
            this.options.push(tag);
            this.value.push(tag);
        },
        search() {
            console.log("this.location.label", this.location.label)
            //console.log("this.selectedCity", this.selectedCity)
            if (!this.location.label )
            //&& ( this.selectedCity == '' || !this.selectedCity )) 
            return;
            let startDate;
            let endDate;
            if (this.range && this.range.start instanceof Date) {
                startDate = this.range.start.getTime() / 1000;
            } else {
                startDate = undefined;
            }
            if (this.range && this.range.end instanceof Date) {
                endDate = this.range.end.getTime() / 1000;
            } else {
                endDate = undefined;
            }
            this.$emit("searchBtnClicked");
            const zoom = this.location.type == "city" ? 13 : 17;
            //const location = this.location.label ? this.location : this.cities.find(city => city.name == this.selectedCity)
/*             if (this.selectedCity != '') {
                console.log("city selezionata,faccio store dispatch di changeLocation", this.location)
                //this.$store.dispatch("changeLocation", this.location);
            } */
            console.log("thesearch, faccio il push del router", location, this.location.label);
            if(this.location.type == 'city')
                router.get("/Search/"+this.location.name,{
                    ...this.location,
                    tags: this.selectedTags,
                })
            else
                router.get("/Search/"+this.location.city,{
                        ...this.location,
                        tags: this.selectedTags,
                    })
                
            //this.$store.dispatch("changeTags", this.selectedTags);
        },
        setLocationInfo(event) {
            console.log("setLocationInfo", event);
            //this.selectedCity = '';
            //this.location = {};
            const place = event.detail;
            const placeName = place.place_name;
            const placeCoords = place.geometry.coordinates;
            this.location.lng = placeCoords[0];
            this.location.lat = placeCoords[1];
            this.location.label = placeName;
            this.location.city = event.city ?? event.raw.name;
            this.location.type = typeof event.email !== 'undefined' ? "hotel" : "city";
            this.location.id = event.id;
            //è un hotel, aggiungiamo l'id nella query
            if (event.id) this.location.id = event.id;
            if (this.location.type != "city") {
                this.showTags = false;
                this.selectedTags = [];
            } else this.showTags = true;
            /// console.log("salvo location", this.location);
            //this.$store.dispatch("changeLocation", this.location);
        },
    },
    created() {
        if (this.$page.props.location) {
            const locationCloned = _.cloneDeep(this.$page.props.location);
            console.log("location passata da props", locationCloned)
            const s = this.cities.find( city => city.city == locationCloned.city) ;
            this.location = s ? s : this.cities[0];
/*             if(!this.options.includes(locationCloned)){
                console.log("error!",this.cities[0])
                this.location = this.cities[0];
            }
            else
                this.location = locationCloned; */

        }
    },
    computed: {
        /*         ...mapState({
                    mapLocation: (state) => state.map.location,
                    mapTags: (state) => state.map.tags,
                }), */
    },
    components: {
        NominatimGeocoder,
        Multiselect
    },
    //remember:['selectedTags']
    mounted() {
        let URLSearchParams = new URL(document.location).searchParams
        if (URLSearchParams.getAll('tags[]').length > 0) {
            var tags = [...URLSearchParams.getAll('tags[]')];
            this.selectedTags = tags;
        }
    }
};
</script>

<style lang="scss">
.datepicker {
    background-image: url("~assets/images/search/calendar.svg");
    background-position: center right 0.625rem;
    @apply bg-no-repeat pr-0 lg:w-full;
}

.search-button {
    @apply flex justify-center gap-4 text-white;

    p {
        @apply lg:hidden;
    }
}

/* .multiselect__input, .multiselect__content-wrapper, .multiselect__content, .multiselect__element{
    z-index: 5000;
} */
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
