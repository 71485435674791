import _ from "lodash";
export function setRatingText(score) {
  if (score > 9) return "Meraviglioso";
  else if (score > 8.5) return "Eccellente";
  else if (score > 8) return "Fantastico";
  else if (score > 7.5) return "Ottimo";
  else if (score > 7) return "Molto Buono";
  else if (!score) {
    return "N.d";
  }
  else return "Buono"
}

export function setTagRatingBackgroundColor(score) {
  if (score > 8) return "#3B82F6;";
  else if (score > 6) return "#10B981;";
  else if (score > 3) return "#F59E0B;";
  else if (score > 0) return "#EF4444;";
  else if (!score) {
    return "#6B7280;";
  }
  else return "Buono"
}
export function setTagRatingEmoji(score) {
  if (score > 8) return "😄";
  else if (score > 6) return "😊";
  else if (score > 3) return "😐";
  else if (score > 0) return "😟";
  else if (!score) {
    return "🤔";
  }
  else return "Buono"
}

export function sumReviews(home) {
  return ["google", "booking", "tripadvisor"].reduce((previous, key) => {
    let val =
      home &&
        home[`${key}_review_number`] != null
        ? parseInt(home[`${key}_review_number`])
        : 0;
    return previous + val;
  }, 0);
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

/**
 * Sposta al top un elemento di array di oggetti trovato con key e value
 */
export function moveToTop(array, key, value) {
  // Find the index of the element in the array

  const index = array.map(e => e[key]).indexOf(value);
  console.log("index,", index)

  // If the element is not in the array, return the original array
  if (index === -1) {
    return array;
  }

  let tmpElement = array[index];
  console.log("trovato,", tmpElement)
  // Remove the element from its current position
  array.splice(index, 1);

  // Add the element back to the beginning of the array
  array.unshift(tmpElement);

  return array;
}

export const availableCities = [
  { name: 'Cervia', lng: "12.350263321232596", lat: "44.26076831714317", label: 'Cervia', city: 'Cervia', type: 'city' },
  { name: 'Rimini', lng: "12.5684371", lat: "44.0593886", label: 'Rimini', city: 'Rimini', type: 'city' },
  { name: 'Bologna', lng: "11.342671827777757", lat: "44.49754436651612", label: 'Bologna', city: 'Bologna', type: 'city' },
  { name: 'Venezia', lng: "12.337825164781787", lat: "45.43780581672211", label: 'Venezia', city: 'Venezia', type: 'city' },
]

export function objectToQueryString(obj) {
  return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
}

export const tagOptions = [
  "Accessibilità",
  "Adatto Alle Famiglie",
  "Affari",
  "Aria Condizionata",
  "Ambiente",
  "Animali Domestici",
  "Bagno",
  "Bar",
  "Benessere",
  "Camera",
  "Coppie",
  "Cucina",
  "Dormire",
  "La Colazione",
  "Luogo",
  "Natura",
  "Parcheggio",
  "Pulizia",
  "Piscina",
  "Proprietà",
  "Ristorante",
  "Servizio",
  "Trasporto Pubblico",
  "Vita Notturna",
  "Wi-Fi"
];



export const defaultMetaconfig = (count, url, city = null) => {
  let description
  if (!city)
    description = `[Ultimo Aggiornamento 24 ore fa] I migliori hotel in Italia. ${count} Hotel a Bologna, Rimini, Venezia e Cervia. Tutte le valutazioni e descrizioni dettagliate. Leggi i nostri giudizi e scegli l’hotel migliore per te`
  else
    description = `[Ultimo Aggiornamento 24 ore fa] I migliori hotel di ${city}, Italia. ${count} Hotel a ${city}. Tutte le valutazioni e descrizioni dettagliate. Leggi i nostri giudizi e scegli l’hotel migliore per te`

  return {
    links: [
      { rel: "icon", href: `https://airoomer.com/storage/favicon/favicon.ico`, type: "image/svg+xml", sizes: "16x16" },
      { rel: "apple-touch-icon", href: `https://airoomer.com/storage/favicon/apple-touch-icon.png`, type: "image/png" },
      { rel: "help", href: `https://airoomer.com/come_funziona` },
      { rel: "canonical", href: `${url}` },

    ],
    meta: [
      { name: "referrer", content: "always" },
      { name: "robots", content: "index,follow" },
      { name: "keywords", content: `Tag, caratteristiche hotel, recensioni, tags, migliori hotel, valutazione, Hotel, ${city}, alloggi, sistemazioni alberghiere, offerte speciali, pacchetti, offerte weekend, soggiorni brevi, pernottamento ${city} Italia, budget, economico, sconto, risparmio` },
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: description, },
      { property: "og:type", content: "website" },
      { property: "og:description", content: description },
      { property: "og:locale", content: `it_IT` },
      { property: "og:site_name", content: `Airoomer.com` },
      { 'http-equiv': "content-language", 'content': 'it', },
      { 'http-equiv': "content-script-type", 'content': 'text/javascript"', },
      { 'http-equiv': "content-style-type", 'content': 'text/css', },

    ],
  }
};



export const HomeConfig = (count) => {
  const title = "I migliori hotel d'Italia valutati in base alle recensioni degli utenti";
  const description = `${count} presenti su airoomer.com. Trova le migliori strutture valutate in base alle recensioni dei clienti e prenota direttamente con la struttura senza commissioni`;
  return {
    title: title,
    favicon: `https://airoomer.com/storage/favicon/favicon.ico`,
    links: [
      { rel: "icon", href: `https://airoomer.com/storage/favicon/favicon.ico`, type: "image/svg+xml", sizes: "16x16" },
      { rel: "apple-touch-icon", href: `https://airoomer.com/storage/favicon/apple-touch-icon.png`, type: "image/png" },
      { rel: "help", href: `https://airoomer.com/come_funziona` },
      { rel: "canonical", href: `https://airoomer.com/` },

    ],
    meta: [
      { name: "referrer", content: "always" },
      { name: "robots", content: "index,follow" },
      { name: "keywords", content: `Tag, caratteristiche hotel, recensioni, tags, migliori hotel, valutazione, Hotel,  alloggi, sistemazioni alberghiere, offerte speciali, pacchetti, offerte weekend, soggiorni brevi, pernottamento, budget, economico, sconto, risparmio` },
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: description, },
      { property: "og:type", content: "website" },
      { property: "og:description", content: description },
      { property: "og:locale", content: `it_IT` },
      { property: "og:site_name", content: `Airoomer.com` },
      { 'http-equiv': "content-language", 'content': 'it', },
      { 'http-equiv': "content-script-type", 'content': 'text/javascript"', },
      { 'http-equiv': "content-style-type", 'content': 'text/css', },

    ],
  }
};

export const SearchConfig =  (title, description, count, url, city) => {

  //description = `[Ultimo Aggiornamento 24 ore fa] I migliori hotel di ${city}, Italia. ${count} Hotel a ${city}. Tutte le valutazioni e descrizioni dettagliate. Leggi i nostri giudizi e scegli l’hotel migliore per te`

  return {
    title: title,
    links: [
      { rel: "icon", href: `https://airoomer.com/storage/favicon/favicon.ico`, type: "image/svg+xml", sizes: "16x16" },
      { rel: "apple-touch-icon", href: `https://airoomer.com/storage/favicon/apple-touch-icon.png`, type: "image/png" },
      { rel: "help", href: `https://airoomer.com/come_funziona` },
      { rel: "canonical", href: `${url}` },

    ],
    meta: [
      { name: "referrer", content: "always" },
      { name: "robots", content: "index,follow" },
      { name: "keywords", content: `Tag, caratteristiche hotel, recensioni, tags, migliori hotel, valutazione, Hotel, ${city}, alloggi, sistemazioni alberghiere, offerte speciali, pacchetti, offerte weekend, soggiorni brevi, pernottamento ${city} Italia, budget, economico, sconto, risparmio` },
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"  },
      { name: "description", content: description, },
      { property: "og:type", content: "website" },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:locale", content: `it_IT` },
      { property: "og:site_name", content: `Airoomer.com` },
      { 'http-equiv': "content-language", 'content': 'it', },
      { 'http-equiv': "content-script-type", 'content': 'text/javascript"', },
      { 'http-equiv': "content-style-type", 'content': 'text/css', },

    ],
  }
};


export const SlugConfig =  (title, description,  url, city, image, name) => {

  //description = `[Ultimo Aggiornamento 24 ore fa] I migliori hotel di ${city}, Italia. ${count} Hotel a ${city}. Tutte le valutazioni e descrizioni dettagliate. Leggi i nostri giudizi e scegli l’hotel migliore per te`

  return {
    title: title,
    links: [
      { rel: "icon", href: `https://airoomer.com/storage/favicon/favicon.ico`, type: "image/svg+xml", sizes: "16x16" },
      { rel: "apple-touch-icon", href: `https://airoomer.com/storage/favicon/apple-touch-icon.png`, type: "image/png" },
      { rel: "help", href: `https://airoomer.com/come_funziona` }

    ],
    meta: [
      { name: "referrer", content: "always" },
      { name: "robots", content: "index,follow" },
      { name: "keywords", content: `${name}, Tag, caratteristiche hotel, recensioni, tags, migliori hotel, valutazione, Hotel, ${city}, alloggi, sistemazioni alberghiere, offerte speciali, pacchetti, offerte weekend, soggiorni brevi, pernottamento ${city} Italia, budget, economico, sconto, risparmio` },
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"  },
      { name: "description", content: description, },
      { property: "og:type", content: "website" },
      { property: "og:title", content: title },
      { property: "og:description", content: description },
      { property: "og:locale", content: `it_IT` },
      { property: "og:site_name", content: `Airoomer.com` },
      { property: "og:image", content: image},
      { 'http-equiv': "content-language", 'content': 'it', },
      { 'http-equiv': "content-script-type", 'content': 'text/javascript"', },
      { 'http-equiv': "content-style-type", 'content': 'text/css', },

    ],
  }
};


