<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g fill="none" fill-rule="evenodd">
      <g fill="#FFF" fill-rule="nonzero">
        <path
          d="M1032.755 74.754l.995-.995c.33-.33.33-.865.004-1.195l-3.505-3.505c-.158-.159-.373-.247-.598-.247h-.573c.97-1.24 1.547-2.801 1.547-4.5 0-4.039-3.273-7.312-7.313-7.312-4.039 0-7.312 3.273-7.312 7.312 0 4.04 3.273 7.313 7.312 7.313 1.699 0 3.26-.577 4.5-1.547v.573c0 .225.088.44.247.598l3.505 3.505c.33.33.864.33 1.191 0zm-9.443-5.942c-2.485 0-4.5-2.01-4.5-4.5 0-2.485 2.011-4.5 4.5-4.5 2.486 0 4.5 2.011 4.5 4.5 0 2.486-2.01 4.5-4.5 4.5z"
          transform="translate(-1016 -57)"
        />
      </g>
    </g>
  </svg>
</template>
