<template>
    <header class="fixed w-full py-2.5 px-2 sm:px-4 bg-white lg:relative  z-20">
        <div class="flex items-center gap-6">
            <!-- Search Button [Small Screens Only] -->
            <div class="grid place-content-center lg:hidden">
                <button @click="hamburger" aria-label="Search">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                        <path
                            :class="{ 'hidden': showingNavigationDropdown, 'inline-flex': !showingNavigationDropdown }"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16" />
                        <path
                            :class="{ 'hidden': !showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <!-- Header logo -->
            <div>
                <Link aria-label="airoomer.com logo" href="/">
                <SVGRevsAnalyzerLogo class="-mt-2 h-9 w-fit" />
                </Link>
            </div>

            <!-- Navbar -->
            <nav class="flex items-center w-full gap-2">
                <div class="w-full">
                    <ul class="flex gap-2 lg:items-center hidden lg:flex">
                        <li class="min-w-[50%]">

                        </li>
                        <li class="ml-auto">
                            <div>
                                <ul class="flex gap-2 lg:items-center hidden lg:flex">
                                    <li>
                                        <a class="block w-full pl-3 pr-4 py-2  border-transparent text-left text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out"
                                            :href="'/blog/home'">
                                            Alloggiando - Il Blog
                                        </a>
                                    </li>
                                    <li>
                                        <ResponsiveNavLink :href="route('HowItWorks')"
                                            :active="route().current('HowItWorks')">
                                            Come funziona
                                        </ResponsiveNavLink>
                                    </li>
                                    <!--                                     <li class="ml-auto" v-if="!$page.props.auth.user">
                                        <ResponsiveNavLink :href="route('register')" :active="route().current('register')">
                                            Registra la tua struttura
                                        </ResponsiveNavLink>
                                    </li> -->
                                    <li :class="$page.props.auth.user ? 'ml-auto' : ''">
                                        <ResponsiveNavLink :href="route('Preferred')"
                                            :active="route().current('Preferred')">
                                            Preferiti
                                        </ResponsiveNavLink>

                                    </li>

                                    <li class="ml-auto" v-if="$page.props.auth.user">

                                        <owner-dropdown />
                                    </li>
                                </ul>
                            </div>

                        </li>




                        <!--
                        <li class="ml-auto">

                           <UserCard />
                        </li>
                        -->
                    </ul>
                </div>

                <!-- Search Button [Small Screens Only] -->
                <div class="grid place-content-center lg:hidden">
                    <button @click="drawer" aria-label="Search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"
                            stroke="#367cff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-search">
                            <circle cx="11" cy="11" r="8" />
                            <line x1="21" y1="21" x2="16.65" y2="16.65" />
                        </svg>
                    </button>
                </div>
            </nav>

            <!-- Dark Background Transition -->
            <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium"
                enter-to-class="opacity-100" leave-class="opacity-100" leave-active-class="ease-out transition-medium"
                leave-to-class="opacity-0">
                <div @keydown.esc="isOpen = false" v-show="isOpen"
                    class="fixed inset-0 z-10 transition-opacity lg:hidden">
                    <div @click="isOpen = false" class="absolute inset-0 bg-black opacity-50" tabindex="0"></div>
                </div>
            </transition>

            <!-- Drawer Menu -->
            <aside
                class="fixed top-0 left-0 z-30 flex flex-col w-full h-full gap-4 p-5 overflow-auto transition-all duration-300 ease-in-out transform bg-white xs:w-1/2 lg:hidden"
                :class="isOpen || hamburgerisOpen ? 'translate-x-0' : '-translate-x-full'">
                <!-- Close Button -->
                <div class="flex justify-end close">
                    <button @click="isOpen = false; hamburgerisOpen = false" aria-label="Close Menu">
                        <svg class="w-6 h-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                </div>

                <div class="flex flex-col  h-full gap-8 p-4" :class="isOpen ? 'justify-center' : ''">
                    <SVGRevsAnalyzerLogo class="-mt-2 h-9" />

                    <TheSearch v-if="isOpen" ID="drawer-search" @searchBtnClicked="drawer" />
                    <TheNav v-else-if="hamburgerisOpen" ID="drawer-nav" @searchBtnClicked="drawer" />
                </div>
            </aside>
        </div>
        <div class="lg:flex justify-center gap-6 max-w-7xl hidden ">
            <TheSearch />
        </div>
    </header>
</template>

<script>
console.log("import.meta.env.SSR)", import.meta.env.SSR)
import { Link } from '@inertiajs/vue3';
export default {
    components: {
        Link,
    },
    data() {
        return {
            isOpen: false,
            hamburgerisOpen: false,
            showingNavigationDropdown: false
        };
    },
    methods: {
        drawer() {
            this.isOpen = !this.isOpen;
        },
        hamburger() {
            this.hamburgerisOpen = !this.hamburgerisOpen;
        },
    },
    watch: {
        isOpen: {
            immediate: true,
            handler(isOpen) {
                if (import.meta.env.SSR) return;
                if (isOpen) document.body.style.setProperty("overflow", "hidden");
                else document.body.style.removeProperty("overflow");
            },
        },
        hamburgerisOpen: {
            immediate: true,
            handler(hamburgerisOpen) {
                if (import.meta.env.SSR) return;
                if (hamburgerisOpen) document.body.style.setProperty("overflow", "hidden");
                else document.body.style.removeProperty("overflow");
            },
        },
    },
    mounted() {
        if (import.meta.env.SSR) return;
        document.addEventListener("keydown", (e) => {
            if (e.keyCode == 27 && this.isOpen) this.isOpen = false;
            if (e.keyCode == 27 && this.hamburgerisOpen) this.hamburgerisOpen = false;
        });
    },
};
</script>
