<script setup>
import Banner from '@/Components/Banner.vue';
import { usePage } from '@inertiajs/vue3';
const page = usePage();

defineProps({
    title: String,
    noFooter: Boolean,
    head: Object,
    noHead: Boolean,
});

</script>

<template>
    <div>
        <template v-if="!noHead">
            <AppHead :config="head"></AppHead>

            <Banner />
            <the-header />
        </template>
        <div class=" bg-gray-100  lg:pt-0" :class="{'pt-20':!noHead  }">

            <!-- Page Heading -->
            <header v-if="$slots.header" class="bg-white dark:bg-gray-800 shadow">
                <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <slot name="header" />
                </div>
            </header>

            <!-- Page Content -->
            <main>
                <slot />
            </main>
        </div>
        <Footer v-if="!noFooter" />
    </div>
</template>
