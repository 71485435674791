<template>
    <div class="flex flex-col gap-1 lg:gap-4 app-search lg:flex-row lg:items-center">
        <!--         <label class="typo__label pt-3">E' il tuo hotel?</label> -->
        <div>
            <a class="block w-full pl-3 pr-4 py-2  border-transparent text-left text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out" :href="'https://airoomer.com/blog/home'">
                Alloggiando - Il Blog
            </a>
        </div>
        <div>
            <ResponsiveNavLink :href="route('HowItWorks')" :active="route().current('HowItWorks')">
                Come funziona
            </ResponsiveNavLink>
        </div>
        <div>
            <ResponsiveNavLink :href="route('Preferred')">
                Preferiti
            </ResponsiveNavLink>
        </div>
<!--         <div v-if="!$page.props.auth.user">
            <ResponsiveNavLink :href="route('register')">
                Registra la tua struttura
            </ResponsiveNavLink>
        </div> -->
        <div>Città principali:</div>
        <ResponsiveNavLink v-for="city in cities" :href="'/Search/' + city.name">
            {{ city.name }}
        </ResponsiveNavLink>
    </div>
</template>

<script>
import NominatimGeocoder from "./NominatimGeocoder.vue";
import { Multiselect } from "vue-multiselect";
import { usePage } from '@inertiajs/vue3'
import {availableCities,  tagOptions} from "@/utils/hotelUtil"
const page = usePage();

export default {
    data() {
        return {
            $page:page,
            selectedTags: [],
            cities: availableCities,
            options: tagOptions,
            showTags: true,
        };
    },
    methods: {
        addTag(tag) {
            this.options.push(tag);
            this.value.push(tag);
        },
    },
    components: { NominatimGeocoder, Multiselect },
    mounted() {
        let URLSearchParams = new URL(document.location).searchParams
        //console.log(URLSearchParams, URLSearchParams.getAll('tags[]'))
        if (URLSearchParams.getAll('tags[]').length > 0) {
            var tags = [...URLSearchParams.getAll('tags[]')];
            this.selectedTags = tags;
        }
    }
};
</script>

<style lang="scss">
.datepicker {
    background-image: url("~assets/images/search/calendar.svg");
    background-position: center right 0.625rem;
    @apply bg-no-repeat pr-0 lg:w-40;
}

.search-button {
    @apply flex justify-center gap-4 text-white;

    p {
        @apply lg:hidden;
    }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
